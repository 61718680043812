// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Services from 'components/units/unitPages/unit/services';
import { ProminentSpace } from 'components/prominentSpace/index';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  section {
    div {
      h1 {
        @media ${device.tablet} {
          width: 30rem;
        }
        @media ${device.mobile} {
          width: 50%;
        }
      }
      ul li {
        height: auto;
        @media (min-width: 320px) {
          width: 100%;
        }

        @media (min-width: 500px) {
          width: 95%;
        }
        h3 {
          width: 100%;
          margin-bottom: 1.25rem;
          @media ${device.mobile320} {
            width: 90%;
          }
        }
        p {
          margin-bottom: 1.25rem;
          em {
            font-style: normal;
            font-weight: 600;
          }
          :last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: 30px;
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: ${props => (props.marginbottomCustomized ? '70px' : '0')};

    @media ${device.mobile} {
      margin-bottom: ${props => (props.marginbottomCustomized ? '30px' : '0')};
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${props => (props.notMarginPortalPaciente ? '0' : '20px')};
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('6.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckui9zxfkycis0b71313ulbjh', true)}
            fragments={getMarkdown(page, 'ckui9zxfkycis0b71313ulbjh')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            // titleWidth="100%"
          />
        </Part>
        <Part gridColumn={'2 / -2'}>
          <Services
            markdown={getMarkdown(page, 'ckuiafn0oyopn0b71wadtp00c', true)}
            fragments={getMarkdown(page, 'ckuiafn0oyopn0b71wadtp00c')}
            cardHowCanIHelp
            isportalPaciente="17.125rem"
            titleDeskWithPortalPaciente="100%"
            titleDeskMaxWithPortalPaciente="210px"
            heightTitleCardPortalPaciente="58px"
            marginBottomCardPortalPaciente="20px"
            widthTextHiperlink="210px"
            marginLeftPortalPaciente="0 !important"
            isCDIUnits
            isActiveCard={true}
            haveNoButtons
            noMarginCardStyle
            isAdjustmentInCssLayoutMobile
            isFitCardButtonPages
            isPortalPaciente
          />
        </Part>

        <Part
          gridColumn={'1 / -1'}
          notMarginPortalPaciente
          borderBottom
          marginbottomCustomized
        >
          <ProminentSpace
            dataInfor={getMarkdown(page, 'ckuwpqt94ncgo0b754gktuc8x', false)}
            widthIframeDesk="570"
            heightIframeDesk="316"
            height="100%"
            heightMob="100%"
            paddingBottomLap="1rem"
          />
        </Part>
      </GridContainer>
    </>
  );
};

const PortalDoPaciente = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "ckuipfcsw5r8u0c73badsnfkf" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  activeBreaknews
                  breaknews {
                    id
                    name
                    type
                    singletexts
                    markdown
                  }
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <PortalDoPaciente page={response.gcms.site.pages} />;
      }}
    />
  );
};
