import React from 'react';
import {
  Container,
  ContainerIframe,
  ContainerInfor,
  Title,
  Description,
  ContainerArea,
} from './style';
export function ProminentSpace({
  dataInfor,
  widthIframeDesk,
  heightIframeDesk,
  heightMob,
  height,
  paddingBottomLap
}) {
  const iframeData = dataInfor[0].datafragment[0];
  return (
    <>
      <Container height={height} heightMob={heightMob} paddingBottomLap={paddingBottomLap}>
        <ContainerArea>
          <ContainerIframe>
            <iframe
              width={widthIframeDesk}
              height={heightIframeDesk}
              src={iframeData.src}
              title={iframeData.titleVideo}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
          </ContainerIframe>
          <ContainerInfor>
            <Title>{iframeData.title}</Title>
            <Description>{iframeData.descriptions}</Description>
          </ContainerInfor>
        </ContainerArea>
      </Container>
    </>
  );
}
