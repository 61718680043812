import styled from 'styled-components';
import { device } from '../device';

export const Container = styled.div`
  margin-top: 4.375rem;
  display: flex;
  width: 100vw;

  background: linear-gradient(135deg, #1d52a3 0%, #45a7df 100%);
  height: ${({height}) => height ? height : '28.5rem'};
  
  @media ${device.laptop} {
    padding-bottom: ${({paddingBottomLap}) => paddingBottomLap};
    margin-left: -1.25rem;
  }

  @media ${device.mobile} {
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column;
    height: ${({heightMob}) => heightMob ? heightMob : '359px'};
  }
`;

export const ContainerArea = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ContainerIframe = styled.div`
  display: flex;
  margin-left: 8.4375rem;
  margin-top: 4.375rem;
  width: auto;

  @media ${device.laptop} {
    width: 50%;
    margin-left: 30px;
  }

  @media ${device.tablet} {
    width: 50%;
    margin-left: 20px;
  }

  @media (max-width: 414px) and (min-width: 376px) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @media ${device.mobile} {
    width: 100%;
    margin-top: -20px;
  }

  @media (max-width: 375px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 360px) {
    margin-left: 13px;
  }

  @media ${device.mobile320} {
    margin-left: 0;
  }

  iframe {
    border: none;

    @media ${device.mobile} {
      width: 333.7px;
      height: 185px;
    }
  }
`;

export const ContainerInfor = styled.div`
  display: flex;
  margin-top: 4.375rem;
  margin-left: 2.5rem;
  flex-direction: column;
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    width: 40%;
    margin-left: 1.875rem;
  }

  @media ${device.tablet} {
    width: 42%;
  }

  @media ${device.mobile} {
    width: 100%;
    max-width: 333.7px;
    margin-top: 0;
    margin-left: 20px;
  }

  @media (max-width: 414px) and (min-width: 376px) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  @media (max-width: 375px) {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  @media (max-width: 360px) {
    margin-left: 13px;
  }

  @media ${device.mobile320} {
    margin-left: 5px;
  }
`;

export const Title = styled.h2`
  display: flex;
  margin-bottom: 3.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #ffffff;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 30px;
  }

  @media ${device.mobile} {
    margin-bottom: 20px;
  }
`;

export const Description = styled.p`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #ffffff;

  @media ${device.laptop} {
    font-size: 14px;
    line-height: 26px;
  }
`;
